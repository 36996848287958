@use "../config/" as *;
:root {
  --showcase-height: calc(53.5rem - var(--header-height));
}
.sticky-container-eventstrip-showcase {
  & > div {
    @extend %p-0;
  }
  .waf-eventstrip-showcase {
    @extend %p-0;
  }
  .layout-wrapper {
    max-width: 100%;
  }
}
.waf-eventstrip-showcase {
  margin-bottom: 0;
  @extend %article-tagline;
  .showcase-wrapper {
    // height: var(--showcase-height);
    height: 52.8rem;
  }
  .article {
    &-wrap {
      @extend %h-100;
    }
    &-item {
      @extend %relative;
      @extend %h-100;
    }
    &-content {
      @include position-combo(x-center, calc(var(--header-height) + 3rem), 0);
      @extend %px-4;
      @extend %flex-column;
      @extend %text-center;
      @extend %zindex-overlay-icon;
    }
    &-title {
      @extend %neutral-0;
      @extend %title-40;
      @extend %my-4;
      @include truncate-text(3);
    }
    &-description {
      @include truncate(1, 24, 28);
      @extend %neutral-0;
    }
    &-thumbnail {
      @extend %h-100;
      .img-box {
        border-radius: 0 0 1.6rem 1.6rem;
        @extend %h-100;
        @extend %common-gradient;
      }
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    &-logo {
      width: auto;
      height: 9rem;
      order: -1;
      @extend %text-center;
      @extend %mx-auto;
      @extend %flex;
      img {
        object-fit: contain;
        filter: brightness(0) invert(1);
        @extend %h-100;
        @extend %w-100;
      }
      .three-star-sponsor-logo {
        filter: none;
      }
    }
    &-status {
      @extend %d-none;
    }
    &-meta {
      flex-direction: column-reverse;
      @extend %flex-column-n-c;
    }
  }
  .meta {
    @extend %gap-2;
    @extend %neutral-0;
    @extend %flex-c-c;
    @extend %body-lb;
    @extend %mb-3;
  }
  .country-flag {
    width: 2.4rem;
    height: 2.4rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    @extend %circle-radius;
  }
  .social {
    &-list {
      display: inline-flex;
      justify-content: center;
      @extend %gap-4;
    }
    &-text {
      font-size: 0;
      &::before {
        font-size: 2rem;
      }
      &.icon-twitter {
        &::before {
          font-size: 1.7rem;
          line-height: 2.4rem;
        }
      }
    }
  }
  .event-status-wrap {
    border: 0.1rem solid var(--negative-50);
    @extend %p-14-6;
    @extend %bg-neutral-0;
    @extend %flex-c-c;
    @extend %border-radius-m;
    @extend %m-14-4;
    .status-text {
      @extend %title-40;
      @extend %negative-90;
      @extend %text-center;
    }
  }
  .cancelled {
    .article-status {
      width: max-content;
      height: 3.6rem;
      border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
      @include position-combo(x-center, -8rem);
      @extend %flex-c-c;
      @extend %bg-negative-0;
      @extend %p-2;
      @extend %gap-2;
      .text {
        @extend %negative-90;
        @extend %text-mb;
      }
    }
  }
}
@include mq(col-tablet) {
  :root {
    // --showcase-height: calc(49.6rem - var(--header-height) - var(--header-redirect-height));
    --showcase-height: 45rem;
  }
  .waf-eventstrip-showcase {
    .showcase-wrapper {
      height: 45rem;
    }
    .article-content {
      top: 42%;
      transform: translateY(-50%);
      bottom: unset;
    }
    .cancelled {
      .article-status {
        top: -13rem;
      }
    }
    .event-status-wrap {
      width: var(--container-max-width);
      margin-inline: auto;
      .status-text {
        font-size: 3.2rem;
      }
    }
  }
}
@include mq(col-desktop) {
  .waf-eventstrip-showcase {
    .article {
      &-content {
        display: block;
        padding-left: var(--container-white-space);
        text-align: left;
        margin-inline: unset;
        top: 45%;
      }
      &-meta {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: var(--space-4);
      }
      &-logo {
        height: 12.8rem;
        max-width: 40%;
        @include position(3rem, var(--container-white-space));
      }
      &-title {
        font-size: 3.6rem;
        width: 50%;
      }
      &-thumbanail {
        .img-box {
          border-radius: 0 0 4.8rem 4.8rem;
        }
      }
    }
    .meta {
      font-size: 2rem;
      justify-content: flex-start;
      margin-bottom: 0;
      &-date {
        gap: var(--space-4);
        &::before {
          content: "";
          width: 0.4rem;
          height: 0.4rem;
          aspect-ratio: 1/1;
          background-color: var(--neutral-0);
          border-radius: 50%;
        }
      }
    }
    .social {
      &-list {
        transform: translateY(calc(-1 * (var(--space-2))));
        align-items: center;
        padding-left: var(--space-4);
        &::before {
          content: "";
          width: 0.4rem;
          height: 0.4rem;
          aspect-ratio: 1/1;
          background-color: var(--neutral-0);
          border-radius: 50%;
        }
      }
      &-text {
        &::before {
          font-size: 2.4rem;
        }
        &.icon-twitter {
          &::before {
            font-size: 2rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
@include mq(col-xl) {
  .waf-eventstrip-showcase {
    .article {
      &-content {
        margin-inline: 8rem;
        padding: 0;
      }
    }
  }
}