@use "./config/" as *;
@forward './main.scss';
@forward "./swiper/swiper-bundle";
@forward './events/fourstar-shoswcase';
@forward './module/development-course';

.waf-eventstrip-showcase{
    .article{
        &-content{
            top: calc(var(--header-height) + 10rem);
        }
        &-status{
            width: max-content;
            border-radius: 10rem;
            @extend %flex-c-c;
            @extend %px-3;
            @extend %py-2;
            @extend %mx-auto;
            @extend %bg-neutral-0;
            @extend %gap-1;
            @include border(1, neutral-20);
            &::before{
                content: 'Application Deadline:';
                font-weight: 700;
            }
        }
    }
}

@include mq(col-lg){
    .waf-eventstrip-showcase{
        .article{
            &-status{
                display: inline-flex;
                margin-inline: 0.8rem 0;
                transform: translateY(-1rem);
            }
        }
    }
}