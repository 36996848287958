@use '../config/' as *;
.waf-component {
    &.waf-course {
        @extend %m-y-12-6;
        @extend %py-0;
    }
}
.waf-course {
    .course {
        &-details {
            @extend %bg-neutral-0;
            @extend %p-10-4;
            @extend %border-radius-m;
            @extend %mb-6;
            .value {
                word-break: break-word;
            }
        }
        &-gallery {
            @extend %mt-10;
            .title {
                @extend %title-40;
                @extend %mb-6;
            }
        }
        &-map {
            @extend %mb-6;
            img {
                aspect-ratio: 16/9;
                max-height: 19.4rem;
            }
        }
        &-documents {
            @extend %bg-neutral-0;
            @extend %p-6-4;
            @extend %border-radius-m;
        }
    }
    .label {
        @extend %body-lb;
    }
    .value {
        span {
            @extend %d-block;
        }
    }
    .img-box {
        aspect-ratio: 1/1;
        @extend %border-radius-s;
        img {
            object-fit: cover;
        }
    }
    .photos-swiper {
        @extend %mr-4-neg;
    }
    .card {
        &-label {
            @extend %title-40;
            @extend %mb-6;
        }
        &-item {
            &:not(:last-of-type) {
                @extend %mb-8;
            }
            p {
                word-break: break-word;
            }
        }
        &-list {
            @extend %event-card-small;
            .title {
                font-size: 1.4rem;
            }
            .btn-link::before {
                content: "\e84d";
            }
        }
    }
    .swiper {
        &-button,
        &-pagination {
            @extend %d-none;
        }
        &-button {
            &-prev,
            &-next {
                border-color: var(--primary-50);
                &::after {
                    color: var(--primary-50);
                    font-size: 2.4rem;
                }
            }
            &.swiper-button-disabled {
                border-color: var(--neutral-20);
                &::after {
                    color: var(--neutral-40);
                }
            }
        }
    }
    .map-container {
        height: 21.4rem;
        @extend %border-radius-r;
    }
}
@include mq(col-tablet) {
    .waf-component {
        &.waf-course {
            margin-block: var(--space-20) var(--space-24);
        }
    }
    .waf-course {
        .course {
            &-details {
                padding: var(--space-12);
                border-radius: 2.4rem;
            }
            &-gallery {
                margin-top: var(--space-17);
                .title {
                    margin-bottom: var(--space-10);
                }
            }
            &-map {
                border-radius: 1.6rem;
                img {
                    border-radius: 1.6rem;
                    max-height: 25.4rem;
                }
            }
            &-documents {
                padding: var(--space-6);
            }
        }
        .photos-swiper {
            margin-right: 0;
            overflow-x: clip;
            overflow-y: visible;
        }
        .swiper {
            &-button {
                display: flex;
                top: -9rem;
                left: auto;
                &-prev {
                    right: 5.6rem;
                }
                &-next {
                    right: 0;
                }
            }
            &-pagination {
                width: max-content;
                display: inline-block;
                top: -6rem;
                right: 12.8rem;
                left: auto;
                bottom: unset;
                font-size: 1.6rem;
            }
        }
        .card {
            &-item {
                gap: var(--space-6);
                display: flex;
            }
            &-head {
                width: 24%;
                flex-shrink: 0;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-course {
        .waf-body {
            display: grid;
            grid-template-columns: 63% auto;
            gap: var(--space-6);
        }
        .card-list {
            .card-item:not(:last-child) {
                margin-bottom: var(--space-4);
            }
        }
        .course {
            &-details {
                grid-area: 1/1/6/2;
                margin-bottom: 0;
            }
            &-map {
                grid-area: 1/2/2/2;
                margin-bottom: 0
            }
            &-documents {
                grid-area: 2/2/3/2;
                > .card-item {
                    flex-direction: column;
                    gap: 0;
                }
                .card {
                    &-head {
                        width: auto;
                    }
                }
            }
        }
        .map-container {
            height: 25.4rem;
            border-radius: var(--space-4);
        }
    }
}